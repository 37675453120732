// backend handlers
import LoginHandler from './LoginHandler'
import {DashboardUpdateHandler, DashboardRemovedHandler, Dashboard6} from './DashboardHandler'
import {ResourceChangedHandler, ResourceDeletedHandler, ResourceOsUpdateHandler} from './ResourceHandler'
import {OsUpdatedHandler, OsCanceledHandler} from './OsHandler'

function HandlersFactory(dashboardInstance) {
    return [
        new LoginHandler(dashboardInstance), 
        new DashboardUpdateHandler(dashboardInstance),
        new DashboardRemovedHandler(dashboardInstance),
        new ResourceChangedHandler(dashboardInstance),
        new ResourceDeletedHandler(dashboardInstance),
        new ResourceOsUpdateHandler(dashboardInstance),
        new OsUpdatedHandler(dashboardInstance),
        new OsCanceledHandler(dashboardInstance),
        new Dashboard6(dashboardInstance)
    ]
}

export default HandlersFactory