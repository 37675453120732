<template>
  <div class="container-fluid p-0 m-0 page pb-3">
    <!-- modal de carga: Aparece quando precisa-se exibir alguma mensagem direta ao usuário. Ex: conexão caiu -->
    <loading-modal
      v-if="showModal"
      :title="modal.title"
      :message="modal.message"
    />

    <!-- cabeçalho do dashboard: logotipo, nome do dashboard e botão de sair -->
    <header
      class="d-flex align-items-center mb-0 px-2 pt-1"
      v-if="!$route.query.noHeader"
    >
      <horizontal-link :xDimensaoTela="windowWidth" />

      <strong class="text-center flex-grow-1"
        >{{ dashboardName }} ({{ $route.params.code }})</strong
      >

      <button
        class="btn btn-secondary me-2"
        :title="mensagemIconeFiltro"
        @click="OnShowCardsAtrasados()"
      >
        <i :class="isDisplayIconeFiltro"></i>
      </button>

      <button
        class="btn btn-secondary me-2"
        @click="OnDisplayDescription()"
        :title="mensagemIconeInformacoes"
      >
        <i v-if="isDisplayDescription != ''" :class="isDisplayDescription"></i>
        <i v-else class="fas fa fa-angle-double-up"></i>
      </button>

      <button
        v-if="(parseInt(windowWidth) > 375) && (!cpsweb)"
        class="btn btn-secondary me-2"
        :title="mensagemIconeTelaCheia"
        @click="goFullscreen"
      >
        <i
          class="fas"
          :class="{ 'fa-expand': !isFullscreen, 'fa-compress': isFullscreen }"
        ></i>
      </button>
      <button
        v-if="(parseInt(windowWidth) > 375) && (!cpsweb)"
        class="btn btn-primary"
        title="Sair do Dashboard"
        @click="$router.replace('/')"
      >
        <i class="fas fa-sign-out-alt"></i>
      </button>
    </header>

    <!-- Corpo do dashboard: cards -->
    <div class="container-fluid">
      <template v-if="resources.length > 0">
        <!-- define quantas colunas tem de acordo com a largura da tela -->
        <div
          class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 gy-4"
        >
          <dashboard-card
            v-for="resource in ascSortedResources"
            :key="resource.id"
            :resource="resource"
            :showDescription="isDisplayDescription"
            :ShowCards="isShowCards"
            :estilo_apontamento="estiloApontamento"
            :versao="versao"
          />
        </div>
      </template>
      <empty-dashboard v-else-if="resources.length == 0" />
    </div>
  </div>
</template>

<script>
import simulatorResourceInjector from "../utils/SimulatorResourceInjector";
import DashboardCard from "../components/Dashboard/DashboardCard.vue";
import LoadingModal from "../components/LoadingModal.vue";
import EmptyDashboard from "../components/Dashboard/EmtpyDashboard.vue";
import HorizontalLink from "../components/HorizontalLink.vue";
import WebSocketClient from "../classes/WebSocketClient";
import HandlersFactory from "../classes/backendHandlers/HandlersFactory";
import Login from "../classes/Login";
import SweetAlert from "sweetalert2";

export default {
  name: "Dashboard",
  data() {
    return {
      cpsweb : false,
      dashboardName: "",
      estiloApontamento: null,
      versao : null,
      showModal: true,
      dashboardLoaded: false,
      modal: {
        title: "Conectando com o servidor",
        message: "Aguarde...",
      },
      isDisplayDescription: window.localStorage.getItem("isDisplayDescription"),
      isDisplayIconeFiltro: window.localStorage.getItem("IconeFiltro"),
      backend: null,
      resources: [],
      token: "",
      isFullscreen: false,
      mensagemIconeTelaCheia: window.localStorage.getItem(
        "mensagemIconeTelaCheia"
      ),
      isShowCards: window.localStorage.getItem("ShowCards"),
      mensagemIconeFiltro: window.localStorage.getItem("mensagemIconeFiltro"),
      windowWidth: window.innerWidth,
      mensagemIconeInformacoes: window.localStorage.getItem(
        "mensagemIconeInformacoes"
      ),
    };
  },

  computed: {

    ascSortedResources() {
      return this.resources
        .map((e) => e)
        .sort((a, b) => a.priority - b.priority);
    },
  },

  methods: {
    goFullscreen() {
      if (!this.isFullscreen) {
        document.body
          .requestFullscreen()
          .then(() => (this.isFullscreen = true))
          .catch((err) =>
            SweetAlert.fire({
              title: "Seu dispositivo não suporta tela cheia",
              text: `Erro: ${err.message} (${err.name})`,
              icon: "info",
            })
          );
        this.mensagemIconeTelaCheia = "Sair do modo de tela cheia.";

        window.localStorage.setItem(
          "mensagemIconeTelaCheia",
          this.mensagemIconeTelaCheia
        );
      } else {
        document.exitFullscreen().then(() => (this.isFullscreen = false));
        this.mensagemIconeTelaCheia = "Mostar em tela cheia";
        window.localStorage.setItem(
          "mensagemIconeTelaCheia",
          this.mensagemIconeTelaCheia
        );
      }
    },

    OnDisplayDescription() {
      if (this.isDisplayDescription == "fas fa fa-angle-double-down") {
        this.isDisplayDescription = "fas fa fa-angle-double-up";
        this.mensagemIconeInformacoes =
          "Mostar somente informações principais.";
      } else {
        this.isDisplayDescription = "fas fa fa-angle-double-down";
        this.mensagemIconeInformacoes = "Mostar todas informações.";
      }

      window.localStorage.setItem(
        "isDisplayDescription",
        this.isDisplayDescription
      );

      window.localStorage.setItem(
        "mensagemIconeInformacoes",
        this.mensagemIconeInformacoes
      );
    },

    OnShowCardsAtrasados() {
      if (this.isDisplayIconeFiltro == "fas fal fa-exclamation-triangle") {
        this.isDisplayIconeFiltro = "fas fa-solid fa-border-all";
        this.mensagemIconeFiltro = "Filtrar todos os apontamentos.";
        this.isShowCards = "true";
      } else {
        this.isDisplayIconeFiltro = "fas fal fa-exclamation-triangle";
        this.mensagemIconeFiltro =
          "Filtrar somente apontamentos com o tempo excedido.";
        this.isShowCards = "false";
      }

      localStorage.setItem("ShowCards", this.isShowCards);

      window.localStorage.setItem("IconeFiltro", this.isDisplayIconeFiltro);

      window.localStorage.setItem(
        "mensagemIconeFiltro",
        this.mensagemIconeFiltro
      );
    },

    // métodos em uso
    onBackendConnected() {
      this.modal.title = "Carregando o dashboard";
      this.modal.message = "Aguarde...";
      this.backend.doLogin(this.code, this.pass);
    },

    OnMessageBeforehandlers(message) {
      //  console.log("message2");
      //  console.log(message);

      if (message.action == 99) {
        this.$router.replace("/");
        return true;
      }

      if (message.action == 98) {
        alert("Senha inválida ");
        return true;
      }
    },

    onBackendDisconnected(code) {
      if (code === 4006) {
        this.showModal = true;
        this.modal.title = "Há uma nova versão do dashboard disponível ;)";
        this.modal.message =
          "Por favor, atualize a página para receber a atualização";
        return false;
      }

      if (code === 4000 || code === 4004) {
        this.$router.replace("/");
        return false;
      } else if (code === 4001) {
        try {
          const login = new Login(this.$route.params.code, this.pass, false);
          login
            .doLogin()
            .then((ok) => {
              if (ok) {
                this.token = login.token;
                this.connectToBackend();
              } else this.$router.replace("/");
            })
            .catch(() => {
              this.$router.replace("/");
            });
          return false;
        } catch (e) {
          //   console.log(e);
          this.$router.replace("/");
        }
      }

      this.showModal = true;
      this.modal.title = "Sem conexão com o servidor";
      this.modal.message =
        "Não se preocupe. Reconectaremos assim que estiver tudo bem ;)";
      return true;
    },

    connectToBackend() {
      this.backend = new WebSocketClient(
        this.onBackendConnected,
        this.onBackendDisconnected,
        this.OnMessageBeforehandlers,
        `resourceClient-${this.$route.params.code}`
      );

      this.backend.addHandlers(HandlersFactory(this));
    },
    TrocaDash() {
      location.reload();
    },
  },

  mounted() {
   
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };

    if (this.$route.query.cpsweb != undefined) {
      this.cpsweb = JSON.parse(this.$route.query.cpsweb);
    }

    if (this.$route.params.code == "simul" && !this.$route.query.empty) {
      this.dashboardName = "SIMULAÇÃO";
      Array.prototype.push.apply(this.resources, simulatorResourceInjector());
      this.showModal = false;
    } else {
      this.token = window.localStorage.getItem("token");

      var ArrayTokens = this.$route.params.code.split("-");

      if (
        sessionStorage.getItem("ultToken") == null ||
        sessionStorage.getItem("ultToken") == ""
      ) {
        this.code = ArrayTokens[0];
        sessionStorage.setItem("ultToken", this.code);
      } else {
        let posicaoUltToken = ArrayTokens.indexOf(
          sessionStorage.getItem("ultToken")
        );
        if (ArrayTokens[posicaoUltToken + 1] == undefined) {
          this.code = ArrayTokens[0];
          sessionStorage.setItem("ultToken", this.code);
        } else {
          this.code = ArrayTokens[posicaoUltToken + 1];
          sessionStorage.setItem("ultToken", this.code);
        }
      }

      this.pass = window.sessionStorage.getItem("pass");

      if (
        !this.token ||
        this.$route.params.code != window.localStorage.getItem("code")
      )
        this.onBackendDisconnected(4001);
      else this.connectToBackend();
    }

    if (localStorage.getItem("ShowCards") == null) {
      this.isDisplayIconeFiltro = "fas fa-solid fa-border-all";
      this.isShowCards = true;
      this.OnShowCardsAtrasados();
    }

    if (ArrayTokens.length > 1) {
    
      setInterval(() => {
        this.TrocaDash();
      }, this.$route.query.intervalo * 60000);
    }
  },

  beforeUnmount() {
    if (this.backend) this.backend.close();
  },

  components: { DashboardCard, LoadingModal, EmptyDashboard, HorizontalLink },
};
</script>

<style scoped>
.page {
  height: 100vh;
  background-color: #efefef;
  overflow-y: auto;
}

.header-image {
  height: 30px;
}
</style>