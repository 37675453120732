const backend_codes = require("../../backend_codes");
const Handler = require("./Handler");
//import Dashboard from '../../views/Dashboard.vue'

class DashboardUpdateHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.DASHBOARD_UPDATED
    }

    _execute(msg) {
      //  console.log('_execute')
        
      //  console.log(msg)
        this._setDashboardName(msg.dashboard_name) 
        this._setEstiloApontamento(msg.estilo_apontamento)
        this._setVersaoServer(msg.versao)
        this._reloadResources(msg.content)
    }
}

class DashboardRemovedHandler extends Handler {
    _doHandle(action) {
        return action === backend_codes.DASHBOARD_REMOVED
    }

    _execute() {
        this._data.modal.title = 'Oops! Esse dashboard foi excluído'
        this._data.modal.message = 'Recarregue a página para escolher outro dashboard'
        this._data.showModal = true
    }
}


class Dashboard6 extends Handler {
    _doHandle(action) {
        return action === 6
    }

    _execute() {
       // this.$router.replace('/')
        this._data.modal.title = 'Onde mapeio as informações'
        this._data.modal.message = 'Recarregue a página para escolher outro dashboard'
        this._data.showModal = true;
   //     Dashboard._setDashboardName('teste')
    }
}

module.exports = {
    DashboardUpdateHandler,
    DashboardRemovedHandler,
    Dashboard6
}