<template>
  <div
    v-show="PassoudoPrevisto && ShowCards == 'true' && resource.process"
    class="col"
  >
    <div
      class="af-card p-2 border border-1 rounded border-white"
      :style="{ height: '100%' }"
    >
      <div
        v-bind:style="{
          backgroundColor: resource.colorStatus,
          height: 30 + 'px',
          marginBottom: -1 + 'px',
          borderRadius: '5px',
        }"
      >
        <span
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 16 + 'px',
            marginLeft: 5 + 'px',
          }"
        >
          <template v-if="resource.os || resource.last_note">
            <span v-if="resource.os"
              ><i class="far fa-play-circle"></i>{{ inicioApontamento }}</span
            >
            <span v-else
              ><i class="fas fa-pause-circle"></i> {{ lastNoteTimestamp }}</span
            >
          </template>
        </span>

        <span
          v-if="resource.os"
          title="Tempo decorrido do apontamento."
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 16 + 'px',
            marginLeft: 18 + 'px',
          }"
        >
          <i class="fas fa-stopwatch"></i> {{ TempoExecucao }}
        </span>

        <span
          v-else
          title="Tempo parado desde o último apontamento."
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 16 + 'px',
            marginLeft: 18 + 'px',
          }"
        >
          <i class="fas fa-stopwatch"></i> {{ TempoExecucao }}
        </span>
      </div>

      <!-- Sensoriamento -->
      <div
        v-if="
          resource.status_sensor != null && resource.status_sensor != undefined
        "
        v-bind:style="{
          backgroundColor: StatusSensor(resource.status_sensor),
          height: 30 + 'px',
          marginBottom: -1 + 'px',
          marginTop: 10 + 'px',
          borderRadius: '5px',
        }"
      >
        <span
          title=""
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 14 + 'px',
            marginLeft: 5 + 'px',
          }"
        >
          <i class="fas fa-power-off"></i
          ><b style="font-size: 16px; font-weight: 600; margin-left: 5px">
            {{ TempoSensor.tempo }}</b
          >
        </span>

        <span
          title="Disponibilidade Hoje."
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 14 + 'px',
            marginLeft: 10 + 'px',
          }"
        >
          <i class="fas fa-tachometer-alt"></i
          ><b style="font-size: 14px; font-weight: 600; margin-left: 5px"
            >{{ resource.disponibilidade_hoje_sensor }}%</b
          >
        </span>

        <span
          title="Disponibilidade Ontem."
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 14 + 'px',
            marginLeft: 10 + 'px',
          }"
        >
          <i class="fas fa-history"></i>
          <b style="font-size: 14px; font-weight: 600; margin-left: 5px"
            >O: {{ resource.disponibilidade_ontem_sensor }}%</b
          >
        </span>

        <span
          title="Disponibilidade 30 dias."
          v-bind:style="{
            color: resource.colorFont,
            marginLeft: 5 + 'px',
            fontSize: 16 + 'px',
          }"
        >
          <b style="font-size: 10px; font-weight: 600"
            >30D: {{ resource.disponibilidade_30dias_sensor }}%</b
          >
        </span>
      </div>

      <h1 class="resource-linha1 mt-2">{{ linha1 }}</h1>
      <!-- Cabeçalho: imagem, nome, cronômetros etc -->
      <header class="d-flex pb-0">
        <!-- Foto do recurso -->

        <div
          v-if="checkimage && resource.picture != undefined"
          class="picture flex-shrink-0"
          :style="{ backgroundImage: 'url(' + adjustedPicture + ')' }"
        ></div>

        <div
          v-if="checkimage && resource.url_img != undefined"
          class="picture flex-shrink-0"
          :style="{ backgroundImage: 'url(' + adjustedUrl + ')' }"
        ></div>

        <!-- Cronômetros, nome, cartões etc -->
        <div class="flex-grow-1 p-1">
          <!-- Nome, quantidade de OS e total de horas trabalhadas no dia -->

          <div class="align-items-stretch">
            <div
              class="flex-shrink-0 ms-1"
              v-if="resource.colorStatus != 'red'"
            >
              <span
                v-if="ExibeInfosHsPrevistasTrabalhadas"
                class="spanLinhaProcesso"
              >
                <span
                  class="NumeroProcesso"
                  title="Código do processo produtivo."
                >
                  <i class="far fa-copy"></i> {{ resource.process }}
                </span>

                <span
                  class="OsTotal m-2"
                  title="Tempo estimado do processo produtivo."
                >
                  <i class="fas fa-history"></i> {{ expected_hours }}
                </span>

                <span
                  v-if="ExibeInfosHsPrevistasTrabalhadas"
                  class="TempoTrabalhado m-2"
                  title="Tempo total de horas trabalhadas."
                  v-bind:style="{
                    backgroundColor: colorBackGround,
                    borderRadius: '5px',
                  }"
                >
                  <i class="fas far fa-hourglass-start"></i
                  >{{ TempoTrabalhado }}
                </span>
              </span>

              <span v-else class="spanLinhaProcesso">
                <span
                  v-if="(resource.expected_hours > 0) && (versao >= '2.0.0')"
                  class="TempoTrabalhado m-2"
                  title="Tempo total de horas trabalhadas."
                  v-bind:style="{
                    backgroundColor: colorBackGround,
                    borderRadius: '5px',
                  }"
                >
                  <i class="fas far fa-hourglass-start"></i
                  >{{ TempoTrabalhadoOS }}
                </span>
                <span
                  v-else
                  class="TempoTrabalhado m-2"
                  title="Tempo total de horas trabalhadas."
                  v-bind:style="{
                    borderRadius: '5px',
                  }"
                >
                  <i class="fas far fa-hourglass-start"></i
                  >{{ TempoTrabalhadoOS }}
                </span>
             
                <span
                 v-if="(resource.expected_hours > 0) && (versao >= '2.0.0')"
                  class="OsTotal m-2"
                  title="Tempo estimado tipo de Serviço."
                >
                  <i class="fas fa-history"></i> {{ expected_hours }}
                </span>
              </span>

              <span class="m-2">
                <os-cards v-if="resource.os" :cards="resource.os.cards" />
              </span>
            </div>
          </div>
        </div>
      </header>

      <transition name="bounce">
        <div
          class="infos"
          v-if="showDescription == 'fas fa fa-angle-double-up'"
        >
          <div class="contentLinha2">
            <h1 class="linha2 mt-2">{{ resource.linha2 }}</h1>
          </div>

          <template v-if="resource.linha3 != ''">
            <h1 class="linha3">{{ resource.linha3 }}</h1>
          </template>
          <template v-if="resource.linha4 != ''">
            <h1 class="linha4">{{ resource.linha4 }}</h1>
          </template>

          <div class="card-description mt-1">
            {{ Notas }}
          </div>
        </div>
        <div class="infos" v-else-if="showDescription == ''">
          <template v-if="resource.linha3 != ''">
            <h1 class="linha3">{{ resource.linha3 }}</h1>
          </template>

          <template v-if="resource.linha4 != ''">
            <h1 class="linha4">{{ resource.linha4 }}</h1>
          </template>

          <div class="card-description mt-1">
            {{ Notas }}
          </div>
        </div>
      </transition>
    </div>
  </div>

  <!-- div que mostra todos os card independente se passou da hora prevista ou nao  -->
  <div v-show="ShowCards == 'false'" class="col">
    <div
      class="af-card p-2 border border-1 rounded border-white"
      :style="{ height: '100%' }"
    >
      <div
        v-bind:style="{
          backgroundColor: resource.colorStatus,
          height: 30 + 'px',
          marginBottom: -1 + 'px',
          borderRadius: '5px',
        }"
      >
        <span
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 16 + 'px',
            marginLeft: 5 + 'px',
          }"
        >
          <template v-if="resource.os || resource.last_note">
            <span v-if="resource.os"
              ><i class="far fa-play-circle"></i>{{ inicioApontamento }}</span
            >
            <span v-else
              ><i class="fas fa-pause-circle"></i> {{ lastNoteTimestamp }}</span
            >
          </template>
        </span>

        <span
          v-if="resource.os"
          title="Tempo decorrido do apontamento."
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 16 + 'px',
            marginLeft: 18 + 'px',
          }"
        >
          <i class="fas fa-stopwatch"></i> {{ TempoExecucao }}
        </span>

        <span
          v-else
          title="Tempo parado desde o último apontamento."
          v-bind:style="{
            color: resource.colorFont,
            fontSize: 16 + 'px',
            marginLeft: 18 + 'px',
          }"
        >
          <i class="fas fa-stopwatch"></i> {{ TempoExecucao }}
        </span>
      </div>

      <!-- Sensoriamento -->
      <div
        v-if="
          resource.status_sensor != null && resource.status_sensor != undefined
        "
        v-bind:style="{
          backgroundColor: StatusSensor(resource.status_sensor),
          height: 30 + 'px',
          marginBottom: -1 + 'px',
          marginTop: 10 + 'px',
          borderRadius: '5px',
        }"
      >
        <span
          title=""
          v-bind:style="{
            color: corMenorque10,
            fontSize: 14 + 'px',
            marginLeft: 5 + 'px',
          }"
        >
          <i class="fas fa-power-off"></i
          ><b style="font-size: 14px; font-weight: 600; margin-left: 5px">
            {{ TempoSensor.tempo }}</b
          >
        </span>

        <span
          title="Disponibilidade Hoje."
          v-bind:style="{
            color: corMenorque10,
            fontSize: 14 + 'px',
            marginLeft: 5 + 'px',
          }"
        >
          <i class="fas fa-tachometer-alt"></i
          ><b style="font-size: 14px; font-weight: 600; margin-left: 5px"
            >{{ resource.disponibilidade_hoje_sensor }}%</b
          >
        </span>

        <span
          title="Disponibilidade Ontem."
          v-bind:style="{
            color: corMenorque10,
            fontSize: 14 + 'px',
            marginLeft: 5 + 'px',
          }"
        >
          <i class="fas fa-history"></i>
          <b style="font-size: 14px; font-weight: 600; margin-left: 5px"
            >{{ resource.disponibilidade_ontem_sensor }}%</b
          >
        </span>
      </div>

      <h1 class="resource-linha1 mt-2">{{ linha1 }}</h1>
      <!-- Cabeçalho: imagem, nome, cronômetros etc -->
      <header class="d-flex pb-0">
        <!-- Foto do recurso -->
        <div
          v-if="checkimage && resource.picture != undefined"
          class="picture flex-shrink-0"
          :style="{ backgroundImage: 'url(' + adjustedPicture + ')' }"
        ></div>

        <div
          v-if="checkimage && resource.url_img != undefined"
          class="picture flex-shrink-0"
          :style="{ backgroundImage: 'url(' + adjustedUrl + ')' }"
        ></div>

        <!-- Cronômetros, nome, cartões etc -->
        <div class="flex-grow-1 p-1">
          <!-- Nome, quantidade de OS e total de horas trabalhadas no dia -->

          <div class="align-items-stretch">
            <div
              class="flex-shrink-0 ms-1"
              v-if="resource.colorStatus != 'red'"
            >
              <span
                v-if="ExibeInfosHsPrevistasTrabalhadas"
                class="spanLinhaProcesso"
              >
                <span
                  class="NumeroProcesso"
                  title="Código do processo produtivo."
                >
                  <i class="far fa-copy"></i> {{ resource.process }}
                </span>

                <span
                  class="OsTotal m-2"
                  title="Tempo estimado do processo produtivo."
                >
                  <i class="fas fa-history"></i> {{ expected_hours }}
                </span>

                <span
                  v-if="ExibeInfosHsPrevistasTrabalhadas"
                  class="TempoTrabalhado m-2"
                  title="Tempo total de horas trabalhadas."
                  v-bind:style="{
                    backgroundColor: colorBackGround,
                    borderRadius: '5px',
                  }"
                >
                  <i class="fas far fa-hourglass-start"></i
                  >{{ TempoTrabalhado }}
                </span>
              </span>

              <span v-else class="spanLinhaProcesso">
                <span
                  v-if="resource.expected_hours > 0"
                  class="TempoTrabalhado m-2"
                  title="Tempo total de horas trabalhadas."
                  v-bind:style="{
                    backgroundColor: colorBackGround,
                    borderRadius: '5px',
                  }"
                >
                  <i class="fas far fa-hourglass-start"></i
                  >{{ TempoTrabalhadoOS }}
                </span>
                <span
                  v-else
                  class="TempoTrabalhado m-2"
                  title="Tempo total de horas trabalhadas."
                  v-bind:style="{
                    borderRadius: '5px',
                  }"
                >
                  <i class="fas far fa-hourglass-start"></i
                  >{{ TempoTrabalhadoOS }}
                </span>

                <span
                  v-if="(resource.expected_hours > 0) && (versao >= '2.0.0')"
                  class="OsTotal m-2"
                  title="Tempo estimado tipo de Serviço."
                >
                  <i class="fas fa-history"></i> {{ expected_hours }}
                </span>
              </span>

              <span class="m-2">
                <os-cards v-if="resource.os" :cards="resource.os.cards" />
              </span>
            </div>
          </div>
        </div>
      </header>

      <transition name="bounce">
        <div
          class="infos"
          v-if="showDescription == 'fas fa fa-angle-double-up'"
        >
          <div class="contentLinha2">
            <h1 class="linha2 mt-2">{{ resource.linha2 }}</h1>
          </div>

          <template v-if="resource.linha3 != ''">
            <h1 class="linha3">{{ resource.linha3 }}</h1>
          </template>
          <template v-if="resource.linha4 != ''">
            <h1 class="linha4">{{ resource.linha4 }}</h1>
          </template>

          <div class="card-description mt-1">
            {{ Notas }}
          </div>
        </div>
        <div class="infos" v-else-if="showDescription == ''">
          <template v-if="resource.linha3 != ''">
            <h1 class="linha3">{{ resource.linha3 }}</h1>
          </template>

          <template v-if="resource.linha4 != ''">
            <h1 class="linha4">{{ resource.linha4 }}</h1>
          </template>

          <div class="card-description mt-1">
            {{ Notas }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
function calcularPorcentagem(tempoEstimado, tempoAtual) {
  return ((tempoAtual / tempoEstimado) * 100).toFixed(2);
}

import OsCards from "./OsCards.vue";
import {
  getTimeSyncFactor,
  getSyncedElapsedSeconds,
  calculateHoursProduced,
  calculateHoursSensor,
  elapsedTempoExecucao,
  // elapsedSecondsToTimeStr,
} from "../../utils/TimeSync";

export default {
  name: "DashboardCard",
  props: {
    resource: Object,
    showDescription: {
      type: String,
    },
    horasProduzidas: {
      type: String,
    },
    ShowCards: {
      type: String,
      default: "false",
    },
    estilo_apontamento: {
      Number,
    },
    versao: {
      String,
    },
  },
  data() {
    return {
      height: "30",
      DataHora: "",
      totalHours: 0,
      timeDiff: 0, // usado para sincronizar a hora da máquina do cliente com a hora do servidor, para fins de cálculos do total de horas trabalhas
      timer: null,
      innerElapsedSeconds: 0,
      TempoTrabalhado: "",
      TempoTrabalhadoOS: "",
      TempoExecucao: "",
      colorBackGround: "",
      fontColor: "",
      ExibeInfosHsPrevistasTrabalhadas: true,
      PassoudoPrevisto: false,
      setup: false,
      // Sensoriamento
      TempoSensor: "",
      corMenorque10: "",
    };
  },
  watch: {
    "resource.total_worked_hours": function () {
      this.calculateTotalHours();
    },

    TempoTrabalhado: function () {
      this.calculateTotalHours();
      this.verificaHorasTrabalhadas(
        this.innerElapsedSeconds,
        this.resource.hours_performed,
        this.resource.expected_hours
      );
    },
  },
  computed: {
    StatusGreen() {
      if (this.resource.count_worked_os == 1) {
        return 1;
      } else {
        return 0;
      }
    },

    expected_hours() {
      let Segundos = this.resource.expected_hours * 3600;
      return elapsedTempoExecucao(Segundos);
    },
    checkimage() {
      if (this.resource.picture == "null") {
        return 0;
      } else {
        return 1;
      }
    },

    StatusOrange() {
      if (this.resource.count_worked_os == 3) {
        return 1;
      } else {
        return 0;
      }
    },

    linha1() {
      return this.resource.linha1;
    },

    Notas() {
      return decodeURIComponent(this.resource.description);
    },

    adjustedPicture() {
      return `data:image;base64,${this.resource.picture.replace(/\n/g, "")}`;
    },
    adjustedUrl() {
      return `${this.resource.url_img}`;
    },
    osInfo() {
      return `${this.resource.os.number}`;
    },
    noteStartTime() {
      const ts = this.resource.os.note_start_timestamp;
      return `${String(ts.getHours()).padStart(2, "0")}:${String(
        ts.getMinutes()
      ).padStart(2, "0")}:${String(ts.getSeconds()).padStart(2, "0")}`;
    },

    lastNoteTimestamp() {
      this.FormatDateHours(this.resource.last_note.timestamp);
      return this.DataHora;
    },

    inicioApontamento() {
      this.FormatDateHours(this.resource.os.note_start_timestamp);
      return this.DataHora;
    },
    totalDayHours() {
      return `${Number(this.totalHours).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}h`;
    },
  },

  methods: {
    StatusSensor(status) {
      if (status == 0) {
        if (this.TempoSensor.MaiorQue10 === false) {
          this.corMenorque10 = "black";
          return "#F9E2E0";
        } else {
          this.corMenorque10 = this.resource.colorFont;
          return "red";
        }
      } else if (status == 1) {
        this.corMenorque10 = this.resource.colorFont;
        return "green";
      } else if (status == 2) {
        this.corMenorque10 = this.resource.colorFont;
        return "grey";
      }
    },

    CalculateHorasTrabalhadasOS() {},
    CalculateSensor() {
      if (
        this.resource.inicio_sensor != null &&
        this.resource.inicio_sensor != undefined
      ) {
        let data = new Date(this.resource.inicio_sensor);
        data.setHours(data.getHours() + 3);
        this.TempoSensor = calculateHoursSensor(data);
      }
    },
    FormatDateHours(dateHours) {
      const data = dateHours.toLocaleString().substring(0, 5);
      const hours = dateHours.toLocaleString().substring(11, 19);
      this.DataHora = data + " " + hours;
    },
    ColorStatus(color) {
      this.statusColor = color;
    },
    calculateTotalHours() {
      var now = new Date();
      this.totalHours = this.resource.total_worked_hours;
      if (this.resource.os)
        this.totalHours +=
          getSyncedElapsedSeconds(
            now,
            this.resource.os.note_start_timestamp,
            this.timeDiff
          ) / 3600;
    },
    verificaHorasTrabalhadas(
      pSegundosAtual,
      pHorasTrabalhadas,
      pHorasPrevistas
    ) {
      if (this.estilo_apontamento > 0) {
        this.ExibeInfosHsPrevistasTrabalhadas = true;
      } else {
        this.ExibeInfosHsPrevistasTrabalhadas = false;
      }

      var SegundosTrabalhados = pHorasTrabalhadas * 3600;
      var SomaSegunTrabalhadosAtual = SegundosTrabalhados + pSegundosAtual;

      var pSegundosPrevistos = pHorasPrevistas * 3600;

      if (SomaSegunTrabalhadosAtual > pSegundosPrevistos) {
        this.PassoudoPrevisto = true;
        this.colorBackGround = "yellow";
      } else {
        this.PassoudoPrevisto = false;
        this.colorBackGround = "white";
      }

      if (
        calcularPorcentagem(pSegundosPrevistos, SomaSegunTrabalhadosAtual) >=
          90.0 &&
        calcularPorcentagem(pSegundosPrevistos, SomaSegunTrabalhadosAtual) <=
          99.99
      ) {
        this.PassoudoPrevisto = true;
        this.colorBackGround = "orange";
      }
    },
  },

  mounted() {

    const setTimer = () => {
      this.timer = window.setTimeout(() => {
        let now = new Date();

        if (this.resource.os) {
          this.innerElapsedSeconds = getSyncedElapsedSeconds(
            now,
            this.resource.os.note_start_timestamp,
            this.timeDiff
          );

          this.TempoExecucao = calculateHoursProduced(
            this.innerElapsedSeconds,
            0
          );

          this.TempoTrabalhado = calculateHoursProduced(
            this.innerElapsedSeconds,
            this.resource.hours_performed
          );

          this.verificaHorasTrabalhadas(
            this.innerElapsedSeconds,
            this.resource.hours_performed,
            this.resource.expected_hours
          );

          if (
            this.resource.hours_worked_os != null &&
            this.resource.hours_worked_os != undefined
          )
            this.TempoTrabalhadoOS = calculateHoursProduced(
              this.innerElapsedSeconds,
              this.resource.hours_worked_os
            );

          this.CalculateSensor();
        } else {
          this.ExibeInfosHsPrevistasTrabalhadas = false;
          if (
            Object.prototype.hasOwnProperty.call(this.resource, "last_note")
          ) {
            this.innerElapsedSeconds = getSyncedElapsedSeconds(
              now,
              this.resource.last_note.timestamp,
              this.timeDiff
            );

            this.TempoExecucao = calculateHoursProduced(
              this.innerElapsedSeconds,
              0
            );

            this.TempoTrabalhado = calculateHoursProduced(
              this.innerElapsedSeconds,
              this.resource.hours_performed
            );

            if (
              this.resource.hours_worked_os != null &&
              this.resource.hours_worked_os != undefined
            )
              this.TempoTrabalhadoOS = calculateHoursProduced(
                this.innerElapsedSeconds,
                this.resource.hours_worked_os
              );
          }

          this.CalculateSensor();
        }

        setTimer();
      }, 1000);
    };
    this.timeDiff = getTimeSyncFactor(this.timestamp, this.elapsedSeconds);
    setTimer();
  },

  created() {
    this.timeDiff = this.resource.os
      ? getTimeSyncFactor(
          this.resource.os.note_start_timestamp,
          this.resource.os.elapsed_seconds
        )
      : 0;
    this.calculateTotalHours();
    this.PassoudoPrevisto = false;
  },
  components: { OsCards },
};
</script>

<style scoped>
.col {
  margin-top: 35px;
}

.picture {
  border-radius: 30px;
  height: 80px;
  width: 80px;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center center;
}

.NumeroProcesso {
  font-size: 0.8em;
}

.TempoTrabalhado {
  font-size: 0.8em;
}

.resource-linha1 {
  font-size: 1.3em;
  height: 1.2em;
  overflow: hidden;
}

.ms-2 {
  font-size: 1em;
}

.m-0 {
  font-size: 22px;
  margin-left: 90px;
}

.d-flex {
  margin-bottom: 10px;
}

.contentLinha2 {
  justify-items: space-between;
}

.m-1 {
  margin-left: 90px;
}

.flex-shrink-0 {
  font-size: 22px;
  justify-items: center;
}

.OsTotal {
  font-size: 0.8em;
}

.bounce-enter-active {
  animation: bounce-in 0.6s;
}

.bounce-leave-active {
  animation: bounce-in 0.6s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.linha4 {
  font-size: 0.8em;
  overflow: hidden;
}

.linha3 {
  font-size: 0.8em;
  overflow: hidden;
}

.linha2 {
  justify-content: space-between;
  font-size: 0.7em;
  margin-top: 5px !important;
  color: blue;
}

.fixed-height {
  height: 1.3em;
  overflow: hidden;
  word-break: break-all;
  padding: 0px;
}

.client-name {
  overflow: hidden;
  height: 1.5em;
  word-break: break-all;
  padding: 0px;
}

.green {
  border-left: 20px solid hsl(147, 100%, 35%);
}

.orange {
  border-left: 40px solid hsl(39, 100%, 49%);
}

.row col {
  border-radius: 100px;
  padding: 0px;
}

.box {
  flex-grow: 1;
  height: 30px;
  border-radius: 5px;
}

.card-description {
  align-items: right;
}

p,
span {
  font-size: 1em;
}

@media only screen and (max-width: 1200px) {
  .resource-linha1 {
    font-size: 1.2em;
  }

  p,
  span {
    font-size: 0.9em;
  }

  .ms-3 {
    margin-left: 13px !important;
  }
}

@media only screen and (max-width: 768px) {
  .resource-linha1 {
    font-size: 1em;
  }

  p,
  span {
    font-size: 0.8em;
  }

  .ms-3 {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 576px) {
  .resource-linha1 {
    font-size: 1.1em;
  }

  p,
  span {
    font-size: 0.8em;
  }

  .ms-3 {
    margin-left: 16px !important;
  }
}

.af-card {
  background-color: #fff;
  box-shadow: 2px 2px 5px #aaa;
  transition: transform 0.2s, box-shadow 0.2s;
}

.af-card:hover {
  transform: scale(102%) translate(-5px, -10px);
  box-shadow: 3px 10px 10px #bbb;
}
</style>