<template>
    <div class="container d-flex flex-column justify-content-center align-items-center full-height text-center">
        <h1>Nenhuma informação para ser exibida</h1>
    </div>
</template>

<script>

export default {
    name: 'EmptyDashboard'
}
</script>

<style scoped>
.full-height {
    height: 90vh
}

</style>
