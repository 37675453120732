<template>
    <template v-if="listCards">
        <span><i class="far fa-clone"></i> {{listCards}}</span>
    </template>
</template>

<script>
//<i class="far fa-credit-card"></i> {{listCards}}
export default {
    name: 'OsCards',
    props: {
        cards: Array
    },
    computed: {
        listCards() {
            if (!this.cards)
                return null
            return this.cards.map(number => String(number)).join('; ')
        }
    }
}
</script>